import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useWatch } from 'react-hook-form';
import { BooleanInput } from 'react-admin';
import Timezone from './Timezone';
import LiveAt from './LiveAt';
import EndAt from './EndAt';
import TakeoverDuration from './TakeoverDuration';
import Frequency from './Frequency';

const PREFIX = 'SuperPost';

const classes = {
  span: `${PREFIX}-span`,
  container: `${PREFIX}-container`,
  field: `${PREFIX}-field`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.span}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },

  [`&.${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.field}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const SuperPost = (props) => {
  const superPost = useWatch({ name: 'superPost' });

  return (
    <Root className={classes.container}>
      <BooleanInput source="superPost" label="Enable a Super Post" />
      <Timezone />
      <LiveAt />
      <EndAt />
      <Frequency />
      {superPost && (
        <Fragment>
          <BooleanInput source="takeoverModeBool" label="Perform Full Screen Takeover" />
          <Typography variant="caption">
            <p>
              The post will be shown in full screen for a duration of your choosing at the selected
              frequency.
            </p>
          </Typography>
          <Typography variant="caption">
            For good display resolution at full screen, ensure images and videos are at least
            1920x1080px and no larger than 250MB.
          </Typography>
          <TakeoverDuration />
        </Fragment>
      )}
    </Root>
  );
};

SuperPost.propTypes = {};

export default SuperPost;
