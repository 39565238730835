import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../DateTime';

const PREFIX = 'LiveAt';

const classes = {
  span: `${PREFIX}-span`,
  container: `${PREFIX}-container`,
  field: `${PREFIX}-field`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.span}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.field}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const LiveAt = (props) => {
  const form = useFormContext();
  const superPost = useWatch({ name: 'superPost' });
  const superPostLiveAt = useWatch({ name: 'superPostLiveAt' });
  const superPostEndAt = useWatch({ name: 'superPostEndAt' });
  const superPostTimeZone = useWatch({ name: 'superPostTimeZone' });

  const handleDateChange = (date) => {
    if (date === '') {
      form.setValue('superPostLiveAt', null);
      return;
    }

    form.setValue('superPostLiveAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const superPostLiveAtValue =
    superPostLiveAt !== undefined && superPostLiveAt !== null ? moment(superPostLiveAt) : null;
  const superPostTimeZoneValue =
    superPostTimeZone !== null ? superPostTimeZone : 'America/Los_Angeles';

  if (superPost === undefined || superPost === false) {
    return <TextInput source="superPostLiveAtDate" label="Super post live date" disabled />;
  }

  const valid = (current) => {
    if (!superPostEndAt) {
      return true;
    }

    const end = moment(superPostEndAt);
    return current.isSameOrBefore(end, 'day');
  };

  return (
    <Root>
      <div className={classes.container}>
        <span className={classes.span}>Super post live date</span>
        <DateTime
          dateFormat="MM/DD/YYYY"
          timeFormat="h:mm a"
          displayTimeZone={superPostTimeZoneValue}
          onChange={handleDateChange}
          isValidDate={valid}
          value={superPostLiveAtValue}
        />
      </div>
    </Root>
  );
};

LiveAt.propTypes = {};

export default LiveAt;
