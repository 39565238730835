import { CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';
import { LoginCallback, LoginInit } from './security';

const customRoutes = [
  <CustomRoutes key="custom-routes" noLayout>
    <Route path="/login/init" element={<LoginInit />} />
    <Route path="/login/callback" element={<LoginCallback />} />
  </CustomRoutes>,
];

export default customRoutes;
