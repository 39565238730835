import { useNotify, useRefresh, useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import UnpublishIcon from '@mui/icons-material/Block';
import { request } from '../../services/request';
import { useOktaAuth } from '../../security/OktaContext';

const PREFIX = 'UnpublishButton';

const classes = {
  approveButton: `${PREFIX}-approveButton`,
  approveIcon: `${PREFIX}-approveIcon`,
  downloadIcon: `${PREFIX}-downloadIcon`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.approveButton}`]: {
    color: '#f44336',
    zIndex: 3000,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.approveIcon}`]: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },

  [`& .${classes.downloadIcon}`]: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

const apiUrl = `${import.meta.env.VITE_API_URL}/v1`;

const UnpublishButton = (props) => {
  const { oktaAuth } = useOktaAuth();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    const updatedRecord = {
      ...record,
      rejected: false,
      published: false,
      publishedAt: null,
      superPost: false,
      superPostTimeZone: '',
      superPostLiveAt: null,
      superPostEndAt: null,
      superPostFrequency: '',
    };

    const requestUrl = `${apiUrl}/queues/${record.id}`;
    const AuthorizationString = `Bearer ${oktaAuth.getAccessToken()}`;

    const requestOptions = {
      url: requestUrl,
      method: 'put',
      headers: {
        Accept: 'application/json',
        Authorization: AuthorizationString,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(updatedRecord),
    };

    try {
      await request(requestOptions);
      refresh();
      notify('Post un-published', { type: 'success' });
    } catch (err) {
      notify(err.errorData.message, { type: 'warning' });
    }
  };

  return (
    <StyledButton size="small" onClick={handleClick} className={classes.approveButton}>
      <UnpublishIcon className={classes.downloadIcon} />
      UN-PUBLISH
    </StyledButton>
  );
};

export default UnpublishButton;
