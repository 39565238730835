import { useWatch } from 'react-hook-form';
import { TextInput, SelectInput } from 'react-admin';

const Frequency = (props) => {
  const superPost = useWatch({ name: 'superPost' });
  const superPostLiveAt = useWatch({ name: 'superPostLiveAt' });
  const superPostEndAt = useWatch({ name: 'superPostEndAt' });

  if (
    superPost === undefined ||
    superPost === false ||
    superPostLiveAt === undefined ||
    superPostLiveAt === null ||
    superPostEndAt === undefined ||
    superPostEndAt === null
  ) {
    return <TextInput source="superPostFrequency" disabled />;
  }

  return (
    <SelectInput
      label="Frequency"
      source="superPostFrequency"
      defaultValue="15-min"
      choices={[
        { id: '15-min', name: 'Every 15 minutes' },
        { id: '30-min', name: 'Every 30 minutes' },
        { id: '1-hr', name: 'Every 1 hour' },
        { id: '2-hr', name: 'Every 2 hours' },
        { id: '4-hr', name: 'Every 4 hours' },
        { id: '8-hr', name: 'Every 8 hours' },
      ]}
    />
  );
};

Frequency.propTypes = {};

export default Frequency;
