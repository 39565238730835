import { Fragment, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Edit,
  BooleanInput,
  DateField,
  FormDataConsumer,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  Labeled,
  useRecordContext,
} from 'react-admin';
import Typography from '@mui/material/Typography';
import DropzoneUploader from '../shared/DropzoneUploader';
import RemoveLocation from '../shared/RemoveLocation';
import SuperPost from '../shared/SuperPost';
import DateInput from './DateInput';
import TimezoneInput from './TimezoneInput';

const schema = yup.object({
  firstName: yup
    .string()
    .max(128, 'First name is to long, max 128 characters')
    .required('First name is required'),
  lastName: yup
    .string()
    .max(128, 'Last name is to long, max 128 characters')
    .required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  subject: yup
    .string()
    .max(128, 'Subject is to long, max 128 characters')
    .required('Subject is required'),
  liveAtTimeZone: yup.string().when('liveAt', {
    is: (liveAt) => liveAt !== null && liveAt !== '',
    then: () => yup.string().required('Timezone is required'),
  }),
  superPost: yup.boolean(),
  superPostFrequency: yup.string().when('superPost', {
    is: true,
    then: (schema) => schema.required('Super Post frequency is required'),
  }),
  superPostEndAt: yup
    .date()
    .nullable()
    .when('superPost', {
      is: true,
      then: (schema) => schema.required('Super Post end at is required'),
    }),
  superPostLiveAt: yup
    .date()
    .nullable()
    .when('superPost', {
      is: true,
      then: (schema) => schema.required('Super Post live at is required'),
    }),
});

const QueueToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" redirect={false} />
    </Toolbar>
  );
};

const PublishContent = (props) => {
  const record = useRecordContext();

  // Anyone can unpublish
  if (record.pushished === true) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  // Only moderators can publish
  if (!record.pushlished) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  return (
    <Fragment>
      <TextInput source="published" helperText="Only moderators can publish content." disabled />
    </Fragment>
  );
};

const RejectedContent = (props) => {
  const record = useRecordContext();

  // Anyone can unpublish
  if (record.rejected === true) {
    return <BooleanInput source="rejected" label="Rejected" />;
  }

  // Only moderators can publish
  if (!record.pushlished) {
    return <BooleanInput source="rejected" label="Rejected" />;
  }

  return (
    <Fragment>
      <TextInput source="rejected" helperText="Only moderators can reject content." disabled />
    </Fragment>
  );
};

const FieldController = (props) => {
  const form = useFormContext();
  const asset = useWatch({ name: 'asset' });
  const liveAt = useWatch({ name: 'liveAt' });
  const published = useWatch({ name: 'published' });
  const rejected = useWatch({ name: 'rejected' });
  const publishedAt = useWatch({ name: 'publishedAt' });
  const superPost = useWatch({ name: 'superPost' });
  const superPostLiveAt = useWatch({ name: 'superPostLiveAt' });
  const superPostEndAt = useWatch({ name: 'superPostEndAt' });
  const superPostFrequency = useWatch({ name: 'superPostFrequency' });

  useEffect(() => {
    if (!asset || liveAt) {
      form.setValue('published', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset, liveAt]);

  useEffect(() => {
    if (rejected) {
      form.setValue('published', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rejected]);

  useEffect(() => {
    if (published) {
      form.setValue('rejected', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [published]);

  useEffect(() => {
    if (
      asset === null ||
      (asset !== undefined && asset !== null && asset.resource_type === 'image')
    ) {
      form.setValue('playAudio', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    if (publishedAt !== null) {
      form.setValue('publishedAt', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publishedAt]);

  useEffect(() => {
    // Reset the liveAt fields if a user filled out all fields for a superPost
    if (
      superPost === true &&
      superPostLiveAt !== '' &&
      superPostEndAt !== '' &&
      superPostFrequency !== ''
    ) {
      form.setValue('liveAt', null);
      form.setValue('liveAtTimeZone', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superPost, superPostLiveAt, superPostEndAt, superPostFrequency]);

  useEffect(() => {
    if (superPost === false) {
      form.setValue('superPostLiveAt', null);
      form.setValue('superPostEndAt', null);
      form.setValue('superPostFrequency', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superPost]);

  return null;
};

const QueueEdit = (props) => {
  return (
    <Edit title="Edit Queue">
      <TabbedForm toolbar={<QueueToolbar />} resolver={yupResolver(schema)}>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <PublishContent />
          <RejectedContent />
          <TextInput source="subject" />
          <TextInput label="First Name" source="firstName" />
          <TextInput label="Last Name" source="lastName" />
          <TextInput label="Email Address" source="email" type="email" />

          <Labeled label="Created At">
            <DateField source="createdAt" showTime />
          </Labeled>

          <Labeled label="Updated At">
            <DateField source="updatedAt" showTime />
          </Labeled>

          <FieldController {...props} />
        </FormTab>

        <FormTab label="attachment">
          <DropzoneUploader />
        </FormTab>

        <FormTab label="go live">
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.superPost && (
                <div style={{ marginTop: '20px' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    This is a Super Post.
                  </Typography>

                  <Typography variant="body2">
                    Remove super post fields to make a one time scheduled post.
                  </Typography>
                </div>
              )
            }
          </FormDataConsumer>

          <FormDataConsumer>
            {({ formData, ...rest }) =>
              !formData.superPost && (
                <Fragment>
                  <DateInput />
                  <TimezoneInput />
                  {/* TODO: find a better solution to make the card non-relative so that the time picker displays correctly */}
                  <div style={{ height: 300 }} />
                </Fragment>
              )
            }
          </FormDataConsumer>
        </FormTab>

        <FormTab label="super post">
          <SuperPost />
        </FormTab>

        <FormTab label="location">
          <ReferenceInput
            source="location"
            reference="locations"
            perPage={1000}
            sort={{ field: 'location', order: 'ASC' }}
          >
            <AutocompleteInput optionText="location" disableClearable />
          </ReferenceInput>
          <RemoveLocation />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default QueueEdit;
