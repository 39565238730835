import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Edit,
  ReferenceInput,
  SaveButton,
  AutocompleteInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { RoleInput } from '../shared/RoleInput';

const schema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  location: yup.string().required('Location is required'),
  status: yup.string().required('Status is required'),
  role: yup.string().required('Role is required'),
});

const UserToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const UserEdit = (props) => {
  return (
    <Edit title="Edit User">
      <SimpleForm toolbar={<UserToolbar />} resolver={yupResolver(schema)}>
        <TextInput source="id" disabled />
        <TextInput label="First Name" source="firstName" />
        <TextInput label="Last Name" source="lastName" />
        <TextInput label="Email Address" source="email" type="email" />
        <ReferenceInput
          source="location"
          reference="locations"
          perPage={1000}
          sort={{ field: 'location', order: 'ASC' }}
        >
          <AutocompleteInput optionText="location" disableClearable />
        </ReferenceInput>
        <RoleInput
          source="role"
          helperText="User role is used as a fallback so no explicit rule is required"
        />
      </SimpleForm>
    </Edit>
  );
};

export default UserEdit;
