import { useState, useEffect } from 'react';
import { AuthSdkError } from '@okta/okta-auth-js';
import OktaContext from './OktaContext';
import OktaError from './OktaError';

const Security = ({ oktaAuth, restoreOriginalUri, onAuthRequired, children }) => {
  const [authState, setAuthState] = useState(() => {
    if (!oktaAuth) {
      return null;
    }
    return oktaAuth.authStateManager.getAuthState();
  });

  useEffect(() => {
    if (!oktaAuth || !restoreOriginalUri) {
      return;
    }

    // Add default restoreOriginalUri callback
    if (oktaAuth.options.restoreOriginalUri && restoreOriginalUri) {
      console.warn(
        'Two custom restoreOriginalUri callbacks are detected. The one from the OktaAuth configuration will be overridden by the provided restoreOriginalUri prop from the Security component.'
      );
    }
    oktaAuth.options.restoreOriginalUri = async (oktaAuth, originalUri) => {
      restoreOriginalUri(oktaAuth, originalUri);
    };

    // Add okta-react userAgent
    if (oktaAuth._oktaUserAgent) {
      oktaAuth._oktaUserAgent.addEnvironment(
        `${import.meta.env.PACKAGE_NAME}/${import.meta.env.PACKAGE_VERSION}`
      );
    } else {
      console.warn(
        '_oktaUserAgent is not available on auth SDK instance. Please use okta-auth-js@^5.3.1 .'
      );
    }

    // Update Security provider with latest authState
    const handler = (authState) => {
      setAuthState(authState);
    };
    oktaAuth.authStateManager.subscribe(handler);

    // Trigger an initial change event to make sure authState is latest
    oktaAuth.start();

    return () => {
      oktaAuth.authStateManager.unsubscribe(handler);
      oktaAuth.stop();
    };
  }, [oktaAuth, restoreOriginalUri]);

  if (!oktaAuth) {
    const err = new AuthSdkError('No oktaAuth instance passed to Security Component.');
    return <OktaError error={err} />;
  }

  if (!restoreOriginalUri) {
    const err = new AuthSdkError('No restoreOriginalUri callback passed to Security Component.');
    return <OktaError error={err} />;
  }

  return (
    <OktaContext.Provider
      value={{
        oktaAuth,
        authState,
        _onAuthRequired: onAuthRequired,
      }}
    >
      {children}
    </OktaContext.Provider>
  );
};

export default Security;
