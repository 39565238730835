import NotificationIcon from '@mui/icons-material/Message';
import NotificationList from './NotificationList';
import NotificationCreate from './NotificationCreate';

const notifications = {
  create: NotificationCreate,
  list: NotificationList,
  icon: NotificationIcon,
};

export default notifications;
