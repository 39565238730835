import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../DateTime';

const PREFIX = 'EndAt';

const classes = {
  span: `${PREFIX}-span`,
  container: `${PREFIX}-container`,
  field: `${PREFIX}-field`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.span}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.field}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const EndAt = (props) => {
  const form = useFormContext();
  const superPost = useWatch({ name: 'superPost' });
  const superPostLiveAt = useWatch({ name: 'superPostLiveAt' });
  const superPostEndAt = useWatch({ name: 'superPostEndAt' });
  const superPostTimeZone = useWatch({ name: 'superPostTimeZone' });

  const handleDateChange = (date) => {
    if (date === '') {
      form.setValue('superPostEndAt', null);
      return;
    }

    const begin = moment(superPostLiveAt);
    const end = moment(date);

    if (begin.isAfter(end)) {
      form.setValue('superPostEndAt', null);
      return;
    }

    form.setValue('superPostEndAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const superPostEndAtValue =
    superPostEndAt !== undefined && superPostEndAt !== null ? moment(superPostEndAt) : '';
  const superPostTimeZoneValue =
    superPostTimeZone !== null ? superPostTimeZone : 'America/Los_Angeles';

  if (
    superPost === undefined ||
    superPost === false ||
    superPostLiveAt === undefined ||
    superPostLiveAt === null
  ) {
    return <TextInput source="superPostEndAtDate" label="Super post end date" disabled />;
  }

  const valid = (current) => {
    const begin = moment(superPostLiveAt);
    return current.isSameOrAfter(begin, 'day');
  };

  return (
    <Root>
      <div className={classes.container}>
        <span className={classes.span}>Super post end date</span>
        <DateTime
          dateFormat="MM/DD/YYYY"
          timeFormat="h:mm a"
          displayTimeZone={superPostTimeZoneValue}
          onChange={handleDateChange}
          isValidDate={valid}
          value={superPostEndAtValue}
        />
      </div>
    </Root>
  );
};

EndAt.propTypes = {};

export default EndAt;
