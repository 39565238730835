import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, useLogin, useTranslate, useNotify } from 'react-admin';
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  Typography,
  Box,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import OktaError from '@app/security/OktaError';

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const location = useLocation();
  const notify = useNotify();
  const login = useLogin();
  const locationState = location.state || {};
  const loginError = locationState.error;

  const handleSubmit = (auth) => {
    setLoading(true);
    login({ provider: 'okta' }).catch((error) => {
      setLoading(false);
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
          ? 'ra.auth.sign_in_error'
          : error.message,
        {
          type: 'error',
          messageArgs: {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                ? error.message
                : undefined,
          },
        }
      );
    });
  };

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'flex-start',
          backgroundBlendMode: 'multiply',
          backgroundColor: (theme) => theme.palette.primary.main,
          backgroundImage: 'url(/bg-login.png)',
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: '6em' }}>
          <Box
            sx={{
              margin: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Avatar sx={{ bgcolor: 'primary.main' }}>
              <LockIcon />
            </Avatar>
          </Box>
          <Box
            sx={{
              marginTop: '1em',
              marginBottom: '1em',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h5">Carvana Collective</Typography>
          </Box>

          <CardActions sx={{ padding: '0 1em 1em 1em' }}>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate('ra.auth.sign_in')}
            </Button>
          </CardActions>

          {loginError && <OktaError error={loginError} />}
        </Card>
      </Box>
    </Form>
  );
};

export default Login;
