import { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import classNames from 'classnames';
import { useNotify } from 'react-admin';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const PREFIX = 'DropzoneUploader';

const classes = {
  assetActions: `${PREFIX}-assetActions`,
  assetButtons: `${PREFIX}-assetButtons`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  removeButton: `${PREFIX}-removeButton`,
  dropzoneContainer: `${PREFIX}-dropzoneContainer`,
  dropzone: `${PREFIX}-dropzone`,
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  video: `${PREFIX}-video`,
  mediaContainer: `${PREFIX}-mediaContainer`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.assetActions}`]: {
    flexDirection: 'column',
    display: 'flex',
    width: '125px',
  },

  [`& .${classes.assetButtons}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.buttonContainer}`]: {
    paddingLeft: 0,
    paddingBottom: '0 !important',
  },

  [`& .${classes.removeButton}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.dropzoneContainer}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.dropzone}`]: {
    background: '#efefef',
    cursor: 'pointer',
    padding: '1rem',
    textAlign: 'center',
    color: '#999',
  },

  [`& .${classes.card}`]: {
    maxWidth: 545,
  },

  [`& .${classes.media}`]: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.video}`]: {
    maxWidth: '350px',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.mediaContainer}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const { VITE_CLOUDINARY_API_UPLOAD_PRESET, VITE_CLOUDINARY_API_KEY } = import.meta.env;

const DropzoneUploader = (props) => {
  const notify = useNotify();

  const form = useFormContext();

  const asset = useWatch({ name: 'asset' });
  const assetUrl = useWatch({ name: 'assetUrl' });

  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const onFileDrop = async (files) => {
    try {
      toggleUpload();

      const formData = new FormData();
      const file = files[0];
      const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
      let isValid = false;

      if (typeof ext === 'string') {
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'webp':
          case 'gif':
          case 'mov':
          case 'mp4':
          case 'm4v':
          case 'wmv':
          case '3gp':
          case 'mkv':
          case 'webm':
            isValid = true;
            break;
          default:
            isValid = false;
        }
      }

      if (!isValid) {
        notify('Invalid attachment provided', { type: 'warning' });
        setUploadProgress(0);
        setUploading(false);

        return;
      }

      const cloudName = import.meta.env.VITE_CLOUDINARY_API_KEY_CLOUD_NAME;

      let url = `https://api.cloudinary.com/v1_1/${cloudName}/auto/upload`;

      formData.append('file', file);
      formData.append('upload_preset', VITE_CLOUDINARY_API_UPLOAD_PRESET); // Replace the preset name with your own
      // TODO: this requires signed upload which requires refactoring of the upload flow
      // formData.append('transformation', 'fl_force_strip');
      formData.append('api_key', VITE_CLOUDINARY_API_KEY); // Replace API key with your own Cloudinary API key
      formData.append('timestamp', (Date.now() / 1000) | 0);

      const response = await axios.post(url, formData, {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        onUploadProgress: (progressEvent) => {
          const uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(uploadProgress);
        },
      });

      form.setValue('asset', response.data);
    } catch (err) {
      console.error(err);
      notify('Invalid attachment provided', { type: 'warning' });
      setUploadProgress(0);
      setUploading(false);
      return;
    }

    toggleUpload();
    setUploadProgress(0);
  };

  const toggleUpload = () => {
    setUploading(!uploading);
  };

  const handleRemoveAsset = () => {
    form.setValue('assetUrl', null);
    form.setValue('asset', null);
  };

  let thumbnail;
  let videoUrl;

  if (asset !== null && assetUrl !== null) {
    if (asset.resource_type === 'image') {
      thumbnail = assetUrl;
    }

    if (asset.resource_type === 'video') {
      videoUrl = assetUrl;
    }
  }

  if (asset !== null && (assetUrl === undefined || assetUrl === null)) {
    let secure_url = asset.secure_url;

    switch (asset.resource_type) {
      case 'image':
        if (asset.format === 'gif') {
          thumbnail = secure_url.replace('/image/upload/', '/image/upload/h_300/');
        } else {
          thumbnail = secure_url.replace('/image/upload/', '/image/upload/h_300/');
          thumbnail = thumbnail.substr(0, thumbnail.lastIndexOf('.')) + '.jpg';
        }
        break;
      case 'video':
        videoUrl = secure_url.substr(0, secure_url.lastIndexOf('.')) + '.mp4';
        break;
      default:
        thumbnail = secure_url;
        videoUrl = secure_url;
    }
  }

  return (
    <Root>
      {asset !== null && asset.resource_type === 'image' && (
        <div className={classes.mediaContainer}>
          <img src={thumbnail} className={classes.media} alt="Post Asset" />

          <CardContent className={classes.buttonContainer}>
            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.assetButtons}
              href={asset.secure_url}
              target="_blank"
            >
              Open Url
            </Button>

            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.removeButton}
              onClick={handleRemoveAsset}
            >
              Remove
            </Button>
          </CardContent>
        </div>
      )}

      {asset !== null && asset.resource_type === 'video' && (
        <div className={classes.mediaContainer}>
          <video className={classes.video} controls>
            <source type="video/mp4" src={videoUrl} />
          </video>

          <CardContent className={classes.buttonContainer}>
            <Typography variant="body1" gutterBottom>
              Filename: {asset.original_filename}
            </Typography>

            <Typography variant="body1" gutterBottom>
              Duration: {asset.duration}
            </Typography>

            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.assetButtons}
              href={asset.secure_url}
              target="_blank"
            >
              Open Url
            </Button>

            <Button
              variant="contained"
              size="small"
              color="primary"
              className={classes.removeButton}
              onClick={handleRemoveAsset}
            >
              Remove
            </Button>
          </CardContent>
        </div>
      )}

      {asset === null && (
        <div className={classes.dropzoneContainer}>
          <Dropzone
            onDrop={onFileDrop}
            accept="video/*,image/*"
            multiple={false}
            disabled={uploading}
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <Fragment>
                  <div
                    {...getRootProps()}
                    className={classNames(`${classes.dropzone}`, {
                      'dropzone--isActive': isDragActive,
                    })}
                  >
                    <input {...getInputProps()} />
                    {uploading ? <p>Uploading</p> : <p>Drop the image or video here</p>}
                  </div>

                  {uploading && <LinearProgress variant="determinate" value={uploadProgress} />}
                </Fragment>
              );
            }}
          </Dropzone>
        </div>
      )}
    </Root>
  );
};

DropzoneUploader.propTypes = {};

export default DropzoneUploader;
