import { Admin, Resource, defaultTheme } from 'react-admin';
import { createTheme } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@app/security';

import CustomLayout from './layout/Layout';
import Login from './layout/Login';

import users from './users';
import permissions from './permissions';
import notifications from './notifications';
import posts from './posts';
import archives from './archives';
import queues from './queues';
import locations from './locations';
import settings from './settings';

import createAuthProvider from './services/authProvider';
import createDataProvider from './services/dataProvider';

import customRoutes from './customRoutes';

const theme = createTheme({
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      ...defaultTheme.palette.primary,
      main: '#00AED9',
      contrastText: '#fff',
    },
    secondary: {
      ...defaultTheme.palette.secondary,
      main: '#00619E',
      contrastText: '#fff',
    },
  },
});

// Auth  Provider
const BASENAME = import.meta.env.PUBLIC_URL || '';
const oktaAuth = new OktaAuth({
  clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
  issuer: import.meta.env.VITE_OKTA_ISSUER,
  redirectUri: `${window.location.origin}${BASENAME}/login/callback`,
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
  disableHttpsCheck: true,
});

const authProvider = createAuthProvider(oktaAuth);

// We have to use use a full page refresh so that permissions are correctly pulled
// due to how the code flows in React Admin's Auth provider.
const restoreOriginalUri = async (oktaAuth, originalUri) => {
  window.location.href = originalUri || '/';
};

// Data  Provider
const apiUrl = `${import.meta.env.VITE_API_URL}/v1`;
const dataProvider = createDataProvider(apiUrl, { oktaAuth });

const App = (props) => {
  return (
    <BrowserRouter>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Admin
          layout={CustomLayout}
          theme={theme}
          authProvider={authProvider}
          dataProvider={dataProvider}
          loginPage={Login}
        >
          {(claims) => {
            return [
              ...customRoutes,
              <Resource key="queues" name="queues" {...queues} />,
              <Resource key="posts" name="posts" {...posts} />,
              <Resource key="archives" name="archives" {...archives} />,
              <Resource key="locations" name="locations" {...locations} />,
              claims?.role === 'admin' ? <Resource key="users" name="users" {...users} /> : null,
              // Permissions are currently unused because groups claim is unavailable and thus we
              // do not infer user's role from their groups.
              // claims?.role === 'admin' ? <Resource name="permissions" {...permissions} /> : null,
              claims?.role === 'admin' ? (
                <Resource key="notifications" name="notifications" {...notifications} />
              ) : null,
              <Resource key="settings" name="settings" {...settings} />,
            ];
          }}
        </Admin>
      </Security>
    </BrowserRouter>
  );
};

export default App;
