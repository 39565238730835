import { useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  BooleanInput,
  DateField,
  Edit,
  FormDataConsumer,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  Labeled,
} from 'react-admin';
import DropzoneUploader from '../shared/DropzoneUploader';

const schema = yup.object({
  queueLimit: yup
    .number()
    .positive()
    .integer()
    .truncate()
    .lessThan(50, 'Queue limit must be a atleast 50')
    .required('Queue limit is required'),
});

const SettingsToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const FieldController = (props) => {
  const form = useFormContext();
  const asset = useWatch({ name: 'asset' });

  useEffect(() => {
    if (!asset) {
      form.setValue('published', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  return null;
};

const SettingEdit = (props) => {
  return (
    <Edit title="Edit Queue">
      <SimpleForm toolbar={<SettingsToolbar />} resolver={yupResolver(schema)}>
        <TextInput source="id" disabled />
        <BooleanInput source="active" />

        <NumberInput
          source="queueLimit"
          step={1}
          defaultValue={50}
          helperText="The number of posts in rotation on the screen. Default is 50."
        />

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.imageUrl === undefined || formData.imageUrl === '') && <DropzoneUploader />
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            (formData.asset === undefined || formData.asset === null) && (
              <TextInput label="Image URL" source="imageUrl" fullWidth />
            )
          }
        </FormDataConsumer>

        <Labeled label="Updated At">
          <DateField source="updatedAt" showTime />
        </Labeled>

        <FieldController {...props} />
      </SimpleForm>
    </Edit>
  );
};

export default SettingEdit;
