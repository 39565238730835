import {
  TopToolbar,
  CreateButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  Pagination,
  RefreshButton,
  TextField,
  TextInput,
  useListContext,
} from 'react-admin';

const Actions = () => (
  <TopToolbar>
    <CreateButton />
    <RefreshButton />
  </TopToolbar>
);

const PostPagination = () => {
  const props = useListContext();
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />
    </Filter>
  );
};

const LocationList = (props) => {
  return (
    <List
      perPage={10}
      pagination={<PostPagination />}
      sort={{ field: 'location', order: 'ASC' }}
      title="Carvana Collective - Locations"
      actions={<Actions />}
      filters={<PostFilter />}
    >
      <Datagrid>
        <TextField source="location" />
        <TextField source="code" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default LocationList;
