import { Alert, AlertTitle } from '@mui/lab';

const OktaError = ({ error }) => {
  if (error.name && error.message) {
    return (
      <Alert severity="error">
        <AlertTitle>{error.name}</AlertTitle>
        {error.message}
      </Alert>
    );
  }
  return <Alert severity="error">{error.toString()}</Alert>;
};

export default OktaError;
