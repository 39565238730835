import {
  TopToolbar,
  CreateButton,
  Datagrid,
  FunctionField,
  DateField,
  List,
  Pagination,
  RefreshButton,
  TextField,
  useListContext,
} from 'react-admin';

const Actions = () => {
  return (
    <TopToolbar>
      <CreateButton />
      <RefreshButton />
    </TopToolbar>
  );
};

const NotificationPagination = () => {
  const props = useListContext();
  return <Pagination rowsPerPageOptions={[10, 25, 50]} {...props} />;
};

const NotificationList = (props) => {
  return (
    <List
      perPage={10}
      pagination={<NotificationPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      title="Carvana Collective - Notifications"
      actions={<Actions />}
    >
      <Datagrid bulkActionButtons={false}>
        <TextField source="title" sortable={false} />
        <TextField source="body" sortable={false} />
        <FunctionField
          label="Link"
          render={(record) =>
            record.link ? (
              <a href={record.link} target="_blank" rel="noreferrer">
                open
              </a>
            ) : (
              'N/A'
            )
          }
        />
        <FunctionField
          label="Sent By"
          render={(record) =>
            record.createdBy ? `${record.createdBy.firstName} ${record.createdBy.lastName}` : 'N/A'
          }
        />
        <DateField source="createdAt" label="Sent At" showTime sortable={false} />
      </Datagrid>
    </List>
  );
};

export default NotificationList;
