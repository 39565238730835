import {
  TopToolbar,
  Filter,
  List,
  Pagination,
  RefreshButton,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  TextInput,
  useListContext,
} from 'react-admin';
import PreviewCard from '../shared/PreviewCard';

const Actions = (props) => {
  return (
    <TopToolbar>
      <RefreshButton />
    </TopToolbar>
  );
};

const PostPagination = () => {
  const props = useListContext();
  return (
    <Pagination labelRowsPerPage="Items per page" rowsPerPageOptions={[10, 25, 50]} {...props} />
  );
};

const PostFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Search" source="q" alwaysOn />

      <ReferenceInput
        source="location"
        reference="locations"
        perPage={1000}
        sort={{ field: 'location', order: 'ASC' }}
        alwaysOn
      >
        <AutocompleteInput label="Locations" optionText="location" />
      </ReferenceInput>

      <SelectInput
        label="Post Type"
        source="type"
        alwaysOn
        choices={[
          // This is the default when no option is selected
          // { id: 'all', name: 'All' },
          { id: 'post', name: 'Post' },
          { id: 'superpost', name: 'Superpost' },
        ]}
      />
    </Filter>
  );
};

const PostList = (props) => {
  return (
    <List
      perPage={10}
      pagination={<PostPagination />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      title="Carvana Collective - Posts"
      actions={<Actions />}
      filters={<PostFilter />}
    >
      <PreviewCard />
    </List>
  );
};

export default PostList;
