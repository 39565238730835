import moment from 'moment-timezone';
import { styled } from '@mui/material/styles';
import { useListContext, RecordContextProvider, Loading } from 'react-admin';
import { Grid, Card, CardContent, ImageListItem, Typography } from '@mui/material';
import QueueButton from './QueueButton';
import PublishButton from './PublishButton';

const PREFIX = 'PreviewCard';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  actions: `${PREFIX}-actions`,
  missingImage: `${PREFIX}-missingImage`,
  liveAtDiv: `${PREFIX}-liveAtDiv`,
  editButton: `${PREFIX}-editButton`,
  assetContainer: `${PREFIX}-assetContainer`,
  image: `${PREFIX}-image`,
  video: `${PREFIX}-video`,
  gridList: `${PREFIX}-gridList`,
  infoSection: `${PREFIX}-infoSection`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.gridContainer}`]: {
    marginLeft: '-20px',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.missingImage}`]: {
    color: 'red',
  },

  [`& .${classes.liveAtDiv}`]: {
    ...theme.typography.button,
    backgroundColor: '#ddd',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },

  [`& .${classes.editButton}`]: {
    zIndex: 3000,
  },

  [`& .${classes.assetContainer}`]: {
    maxHeight: 250,
  },

  [`& .${classes.image}`]: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.video}`]: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.gridList}`]: {
    listStyle: 'none',
  },

  [`& .${classes.infoSection}`]: {
    padding: theme.spacing(1),
  },
}));

const cardStyle = {
  // width: '100%',
  margin: '0.5em',
  // display: 'inline-block',
  // verticalAlign: 'top',
  height: '100%',
};

const checkUrl = function (url) {
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
};

const PreviewCard = (props) => {
  const { data, isLoading } = useListContext();

  if (isLoading) return <Loading />;
  if (data.length === 0) {
    return (
      <CardContent>
        <Typography variant="body2">No results found</Typography>
      </CardContent>
    );
  }

  return (
    <StyledGrid container spacing={3} className={classes.gridContainer}>
      {data?.map((record) => {
        const { archivedUrl } = record;
        const isImage = checkUrl(archivedUrl);
        const creator =
          record.creator !== null
            ? `${record.creator.firstName} ${record.creator.lastName}`
            : 'Email Service';

        return (
          <RecordContextProvider key={record.id} value={record}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Card style={cardStyle}>
                <ImageListItem className={classes.gridList}>
                  {archivedUrl !== null && isImage && (
                    <div className={classes.assetContainer}>
                      <img src={archivedUrl} className={classes.image} alt="Post Asset" />
                    </div>
                  )}

                  {archivedUrl !== null && !isImage && (
                    <div className={classes.assetContainer}>
                      <video controls className={classes.video}>
                        <source type="video/mp4" src={archivedUrl} />
                      </video>
                    </div>
                  )}

                  <div className={classes.infoSection}>
                    {archivedUrl === null && (
                      <div>
                        <Typography variant="h5" className={classes.missingImage}>
                          No asset added
                        </Typography>
                      </div>
                    )}
                    <Typography variant="subtitle1">
                      By: {record.firstName} {record.lastName}
                    </Typography>
                    <Typography variant="body1">Creator: {creator}</Typography>
                    <Typography variant="body1">Email: {record.email}</Typography>
                    <Typography variant="body1">Subject: {record.subject}</Typography>
                    <Typography variant="body1">
                      Published: {record.published ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body1">
                      Created At: {moment(record.createdAt).format('MM/DD/YYYY, h:mm A')}
                    </Typography>
                    <Typography variant="body1">Likes: {record.likeCount}</Typography>
                    {record.publishedAt !== null && (
                      <Typography variant="body1">
                        Published At: {moment(record.publishedAt).format('MM/DD/YYYY, h:mm A')}
                      </Typography>
                    )}
                    <div className={classes.actions}>
                      {archivedUrl !== null && (
                        <PublishButton record={record} className={classes.editButton} />
                      )}

                      {archivedUrl !== null && (
                        <QueueButton record={record} className={classes.editButton} />
                      )}

                      {record.superPost && (
                        <div className={classes.liveAtDiv}>
                          Super post scheduled:
                          <br />
                          {moment
                            .tz(record.superPostLiveAt, record.superPostTimeZone)
                            .format('MM/DD/YYYY, h:mm A')}{' '}
                          {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                          <br />
                          thru
                          <br />
                          {moment
                            .tz(record.superPostEndAt, record.superPostTimeZone)
                            .format('MM/DD/YYYY, h:mm A')}{' '}
                          {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                        </div>
                      )}
                    </div>
                    <Typography variant="caption">
                      <strong>Location:</strong> {record.locationTitle}
                    </Typography>
                    &nbsp;
                    <Typography variant="caption">
                      <strong>Location Code:</strong> {record.locationCode}
                    </Typography>
                  </div>
                </ImageListItem>
              </Card>
            </Grid>
          </RecordContextProvider>
        );
      })}
    </StyledGrid>
  );
};

export default PreviewCard;
