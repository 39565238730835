import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, SimpleForm, SaveButton, TextInput, Toolbar } from 'react-admin';

const schema = yup.object({
  title: yup.string().max(64, 'Maximum length is 64 characters').required('Title is required'),
  // Android Limit = 240, iOS limit = 178
  body: yup.string().max(170, 'Maximum length is 170 characters').required('Message is required'),
  link: yup.string().url('Link has to start with http:// or https://'),
});

const NotificationCreateToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Send Message" redirect="show" />
    </Toolbar>
  );
};

const NotificationCreate = (props) => {
  return (
    <Create title="Send Message">
      <SimpleForm toolbar={<NotificationCreateToolbar />} resolver={yupResolver(schema)}>
        <TextInput label="Title" source="title" helperText="Use no more than 64 characters" />
        <TextInput
          label="Body"
          source="body"
          multiline
          helperText="Use no more than 170 characters"
        />
        <TextInput
          type="url"
          label="Link"
          source="link"
          multiline
          helperText="Use absolute link that starts with http:// or https://"
        />
      </SimpleForm>
    </Create>
  );
};

export default NotificationCreate;
