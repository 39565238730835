import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import Button from '@mui/material/Button';

const PREFIX = 'LocationInput';

const classes = {
  removeLocation: `${PREFIX}-removeLocation`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.removeLocation}`]: {
    marginTop: theme.spacing(1),
  },
}));

const LocationInput = (props) => {
  const form = useFormContext();
  const location = useWatch({ name: 'location' });

  const handleRemoveLocation = () => {
    form.setValue('location', null);
  };

  const locationValue = location ? location : null;

  return (
    <Root>
      {locationValue !== null && (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={handleRemoveLocation}
          className={classes.removeLocation}
        >
          Remove Location
        </Button>
      )}
    </Root>
  );
};

LocationInput.propTypes = {};

export default LocationInput;
