import moment from 'moment-timezone';
import { EditButton, useNotify, useListContext, RecordContextProvider, Loading } from 'react-admin';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import { Grid, Card, ImageListItem, Typography, Button, CardContent } from '@mui/material';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import getOptimizedUrl from '@app/utils/getOptimizedUrl';
import ApproveButton from './ApproveButton';
import PublishButton from './PublishButton';
import RejectButton from './RejectButton';
import SoftRejectButton from './SoftRejectButton';
import UnpublishButton from './UnpublishButton';
import ArchiveButton from './ArchiveButton';

const PREFIX = 'PreviewCard';

const classes = {
  gridContainer: `${PREFIX}-gridContainer`,
  actions: `${PREFIX}-actions`,
  missingImage: `${PREFIX}-missingImage`,
  liveAtDiv: `${PREFIX}-liveAtDiv`,
  editButton: `${PREFIX}-editButton`,
  assetContainer: `${PREFIX}-assetContainer`,
  image: `${PREFIX}-image`,
  video: `${PREFIX}-video`,
  gridList: `${PREFIX}-gridList`,
  infoSection: `${PREFIX}-infoSection`,
  downloadIcon: `${PREFIX}-downloadIcon`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.gridContainer}`]: {
    marginLeft: '-20px',
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1),
  },

  [`& .${classes.actions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.missingImage}`]: {
    color: 'red',
  },

  [`& .${classes.liveAtDiv}`]: {
    ...theme.typography.button,
    backgroundColor: '#ddd',
    marginTop: theme.spacing(1),
    padding: theme.spacing(1),
  },

  [`& .${classes.editButton}`]: {
    zIndex: 3000,
  },

  [`& .${classes.assetContainer}`]: {
    maxHeight: 250,
  },

  [`& .${classes.image}`]: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.video}`]: {
    width: '100%',
    objectFit: 'contain',
    maxHeight: '250px',
  },

  [`& .${classes.gridList}`]: {
    listStyle: 'none',
  },

  [`& .${classes.infoSection}`]: {
    padding: theme.spacing(1),
  },

  [`& .${classes.downloadIcon}`]: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

const cardStyle = {
  // width: '100%',
  margin: '0.5em',
  // display: 'inline-block',
  // verticalAlign: 'top',
  height: '100%',
};

const PreviewCard = (props) => {
  const { data, isLoading } = useListContext();
  const notify = useNotify();

  const handleDownload = (asset) => {
    const fileName = `${asset.public_id}.${asset.format}`;
    notify('Retrieving asset to download, please be patient', { type: 'success' });
    saveAs(asset.secure_url, fileName);
  };

  if (isLoading) return <Loading />;
  if (data.length === 0) {
    return (
      <CardContent>
        <Typography variant="body2">No results found</Typography>
      </CardContent>
    );
  }

  return (
    <StyledGrid container spacing={3} className={classes.gridContainer}>
      {data?.map((record) => {
        const asset =
          record !== undefined && record.asset !== undefined && record.asset !== null
            ? record.asset
            : null;
        const { assetUrl } = record;
        const assetType = asset !== null ? asset.resource_type : null;
        const assetFormat = asset !== null ? asset.format : null;
        const creator =
          record.creator !== null
            ? `${record.creator.firstName} ${record.creator.lastName}`
            : 'Email Service';

        return (
          <RecordContextProvider key={record.id} value={record}>
            <Grid item xs={12} sm={6} md={6} lg={4}>
              <Card style={cardStyle}>
                <ImageListItem className={classes.gridList}>
                  {asset !== null && assetType === 'image' && assetFormat !== 'gif' && (
                    <div className={classes.assetContainer}>
                      <img
                        src={getOptimizedUrl({
                          publicId: asset.public_id,
                          cloudName: import.meta.env.VITE_CLOUDINARY_API_KEY_CLOUD_NAME,
                          resourceType: assetType,
                          width: 347,
                          height: 195,
                        })}
                        className={classes.image}
                        alt="Post Asset"
                      />
                    </div>
                  )}

                  {asset !== null && assetType === 'image' && assetFormat === 'gif' && (
                    <div className={classes.assetContainer}>
                      <img
                        src={getOptimizedUrl({
                          publicId: asset.public_id,
                          cloudName: import.meta.env.VITE_CLOUDINARY_API_KEY_CLOUD_NAME,
                          resourceType: assetType,
                          width: 347,
                          height: 195,
                          crop: 'fill',
                        })}
                        className={classes.image}
                        alt="Post Gif Asset"
                      />
                    </div>
                  )}

                  {assetUrl !== null && assetType === 'video' && (
                    <div className={classes.assetContainer}>
                      <video controls className={classes.video}>
                        <source type="video/mp4" src={assetUrl} />
                      </video>
                    </div>
                  )}

                  <div className={classes.infoSection}>
                    {assetType === null && record.mediaTransferring === true && (
                      <div>
                        <Typography variant="subtitle1" className={classes.missingImage}>
                          Media Transferring. Refresh the page in a few seconds.
                        </Typography>
                      </div>
                    )}
                    {assetType === null && record.mediaTransferring !== true && (
                      <div>
                        <Typography variant="h5" className={classes.missingImage}>
                          No asset added
                        </Typography>
                      </div>
                    )}
                    <Typography variant="subtitle1">
                      By: {record.firstName} {record.lastName}
                    </Typography>
                    <Typography variant="body1">Creator: {creator}</Typography>
                    <Typography variant="body1">Email: {record.email}</Typography>
                    <Typography variant="body1">Subject: {record.subject}</Typography>
                    <Typography variant="body1">
                      Published: {record.published ? 'Yes' : 'No'}
                    </Typography>
                    <Typography variant="body1">
                      Created At: {moment(record.createdAt).format('MM/DD/YYYY, h:mm A')}
                    </Typography>
                    <Typography variant="body1">Likes: {record.likeCount}</Typography>
                    {record.publishedAt !== null && (
                      <Typography variant="body1">
                        Published At: {moment(record.publishedAt).format('MM/DD/YYYY, h:mm A')}
                      </Typography>
                    )}
                    <div className={classes.actions}>
                      <EditButton resource="posts" record={record} className={classes.editButton} />

                      {assetType !== null &&
                        record.liveAt === null &&
                        record.published === false &&
                        record.superPost === false && (
                          <PublishButton record={record} className={classes.editButton} />
                        )}

                      {assetType !== null &&
                        record.liveAt === null &&
                        record.published === false &&
                        record.superPost === true && (
                          <ApproveButton record={record} className={classes.editButton} />
                        )}

                      {record.published === false && (
                        <RejectButton record={record} className={classes.editButton} />
                      )}

                      {record.published === false && (
                        <SoftRejectButton record={record} className={classes.editButton} />
                      )}

                      {record.published === true && (
                        <UnpublishButton record={record} className={classes.editButton} />
                      )}

                      {record.published === true && (
                        <ArchiveButton record={record} className={classes.editButton} />
                      )}

                      {asset !== null && asset.resource_type === 'image' && (
                        <Button size="small" color="primary" onClick={() => handleDownload(asset)}>
                          <DownloadIcon className={classes.downloadIcon} />
                          Download Image
                        </Button>
                      )}

                      {asset !== null && asset.resource_type === 'video' && (
                        <Button size="small" color="primary" onClick={() => handleDownload(asset)}>
                          <DownloadIcon className={classes.downloadIcon} />
                          Download Video
                        </Button>
                      )}

                      {record.liveAt !== null && (
                        <div className={classes.liveAtDiv}>
                          Scheduled at:{' '}
                          {moment
                            .tz(record.liveAt, record.liveAtTimeZone)
                            .format('MM/DD/YYYY, h:mm A')}{' '}
                          ({record.liveAtTimeZone})
                        </div>
                      )}

                      {record.superPost && (
                        <div className={classes.liveAtDiv}>
                          Super post scheduled:
                          <br />
                          {moment
                            .tz(record.superPostLiveAt, record.superPostTimeZone)
                            .format('MM/DD/YYYY, h:mm A')}{' '}
                          {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                          <br />
                          thru
                          <br />
                          {moment
                            .tz(record.superPostEndAt, record.superPostTimeZone)
                            .format('MM/DD/YYYY, h:mm A')}{' '}
                          {record.superPostTimeZone ? `(${record.superPostTimeZone})` : null}
                        </div>
                      )}
                    </div>
                    <Typography variant="caption">
                      <strong>Location:</strong> {record.locationTitle}
                    </Typography>
                    &nbsp;
                    <Typography variant="caption">
                      <strong>Location Code:</strong> {record.locationCode}
                    </Typography>
                  </div>
                </ImageListItem>
              </Card>
            </Grid>
          </RecordContextProvider>
        );
      })}
    </StyledGrid>
  );
};

export default PreviewCard;
