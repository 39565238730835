import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { useFormContext, useWatch } from 'react-hook-form';
import { TextInput } from 'react-admin';
import moment from 'moment-timezone';
import DateTime from '../shared/DateTime';

const PREFIX = 'DateInput';

const classes = {
  span: `${PREFIX}-span`,
  container: `${PREFIX}-container`,
  field: `${PREFIX}-field`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.span}`]: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '.75rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: 1,
    paddingBottom: theme.spacing(1),
  },

  [`& .${classes.container}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: '256px',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.field}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const DateInput = (props) => {
  const form = useFormContext();
  const asset = useWatch({ name: 'asset' });
  const liveAt = useWatch({ name: 'liveAt' });
  const liveAtTimeZone = useWatch({ name: 'liveAtTimeZone' });

  const handleDateChange = (date) => {
    if (date === '') {
      form.setValue('published', false);
      form.setValue('liveAt', null);
      form.setValue('liveAtTimeZone', 'America/Los_Angeles');
      return;
    }

    form.setValue('published', false);
    form.setValue('liveAt', moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSSZ'));
  };

  const liveAtValue = liveAt !== null ? moment(liveAt) : '';
  const liveAtTimeZoneValue = liveAtTimeZone !== null ? liveAtTimeZone : 'America/Los_Angeles';

  return (
    <Root>
      {asset === null && <TextInput source="liveAt" disabled />}

      {asset !== null && (
        <div className={classes.container}>
          <span className={classes.span}>Live at date</span>
          <DateTime
            dateFormat="MM/DD/YYYY"
            timeFormat="h:mm a"
            displayTimeZone={liveAtTimeZoneValue}
            onChange={handleDateChange}
            value={liveAtValue}
          />
        </div>
      )}
    </Root>
  );
};

DateInput.propTypes = {};

export default DateInput;
