import SettingIcon from '@mui/icons-material/Settings';
import SettingList from './SettingList';
import SettingEdit from './SettingEdit';

const settings = {
  list: SettingList,
  edit: SettingEdit,
  icon: SettingIcon,
};

export default settings;
