import { Fragment, useEffect } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  Edit,
  BooleanInput,
  DateField,
  DateTimeInput,
  ReferenceInput,
  AutocompleteInput,
  SaveButton,
  TabbedForm,
  FormTab,
  TextInput,
  Toolbar,
  Labeled,
  useRecordContext,
} from 'react-admin';
import DropzoneUploader from '../shared/DropzoneUploader';
import RemoveLocation from '../shared/RemoveLocation';
import SuperPost from '../shared/SuperPost';

const schema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  email: yup.string().email().required('Email is required'),
  subject: yup
    .string()
    .max(128, 'Subject is to long, max 128 characters')
    .required('Subject is required'),
  superPost: yup.boolean(),
  superPostFrequency: yup.string().when('superPost', {
    is: true,
    then: (schema) => schema.required('Super Post frequency is required'),
  }),
  superPostEndAt: yup
    .date()
    .nullable()
    .when('superPost', {
      is: true,
      then: (schema) => schema.required('Super Post end at is required'),
    }),
  superPostLiveAt: yup
    .date()
    .nullable()
    .when('superPost', {
      is: true,
      then: (schema) => schema.required('Super Post live at is required'),
    }),
});

const PostToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" redirect={false} />
    </Toolbar>
  );
};

const PublishContent = (props) => {
  const record = useRecordContext();

  // Anyone can unpublish
  if (record.pushished === true) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  // Only moderators can publish
  if (!record.pushlished) {
    return <BooleanInput source="published" label="Published / Approved" />;
  }

  return (
    <Fragment>
      <TextInput source="published" helperText="Only moderators can publish content." disabled />
    </Fragment>
  );
};

const FieldController = (props) => {
  const form = useFormContext();

  const asset = useWatch({ name: 'asset' });
  const superPost = useWatch({ name: 'superPost' });

  useEffect(() => {
    if (asset === null || (asset !== null && asset.resource_type === 'image')) {
      form.setValue('playAudio', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  useEffect(() => {
    if (superPost === false) {
      form.setValue('superPostLiveAt', null);
      form.setValue('superPostEndAt', null);
      form.setValue('superPostFrequency', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superPost]);

  return null;
};

const PostEdit = (props) => {
  return (
    <Edit title="Edit Post">
      <TabbedForm toolbar={<PostToolbar />} resolver={yupResolver(schema)}>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <PublishContent />
          <TextInput source="subject" />
          <TextInput label="First Name" source="firstName" />
          <TextInput label="Last Name" source="lastName" />
          <TextInput label="Email Address" source="email" type="email" />
          <DateTimeInput
            label="Published At"
            source="publishedAt"
            helperText="Update the value changes where it is listed regarding all other posts"
          />
          <Labeled label="Created At">
            <DateField source="createdAt" showTime />
          </Labeled>
          <Labeled label="Updated At">
            <DateField source="updatedAt" showTime />
          </Labeled>

          <FieldController {...props} />
        </FormTab>

        <FormTab label="attachment">
          <DropzoneUploader />
        </FormTab>

        <FormTab label="super post">
          <SuperPost />
        </FormTab>

        <FormTab label="location">
          <ReferenceInput
            source="location"
            reference="locations"
            perPage={1000}
            sort={{ field: 'location', order: 'ASC' }}
          >
            <AutocompleteInput optionText="location" disableClearable />
          </ReferenceInput>
          <RemoveLocation />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default PostEdit;
