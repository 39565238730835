import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Create, SaveButton, SimpleForm, TextInput, Toolbar } from 'react-admin';

const schema = yup.object({
  location: yup.string().required('Location is required'),
  code: yup.string().required('Location code is required'),
});

const LocationToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const LocationCreate = (props) => {
  return (
    <Create title="Create Location">
      <SimpleForm toolbar={<LocationToolbar />} resolver={yupResolver(schema)}>
        <TextInput label="Location" source="location" />
        <TextInput label="Location Code" source="code" />
      </SimpleForm>
    </Create>
  );
};

export default LocationCreate;
