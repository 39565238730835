import Button from '@mui/material/Button';
import { useNotify, useRefresh, useRecordContext } from 'react-admin';
import { request } from '../services/request';
import { useOktaAuth } from '../security/OktaContext';

const apiUrl = `${import.meta.env.VITE_API_URL}/v1`;

const KillSwitch = (props) => {
  const { oktaAuth } = useOktaAuth();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    const active = !record.active;
    const updatedRecord = { ...record, active };
    const requestUrl = `${apiUrl}/settings/${record.id}`;
    const AuthorizationString = `Bearer ${oktaAuth.getAccessToken()}`;

    const requestOptions = {
      url: requestUrl,
      method: 'put',
      headers: {
        Accept: 'application/json',
        Authorization: AuthorizationString,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(updatedRecord),
    };

    try {
      await request(requestOptions);
      refresh();
      notify('Updated site settings', { type: 'success' });
    } catch (err) {
      notify(err.errorData.message, { type: 'warning' });
    }
  };

  const { active } = record;
  const copy = active ? 'Take Offline' : 'Take Online';

  return (
    <Button variant="contained" color="secondary" onClick={handleClick}>
      {copy}
    </Button>
  );
};

export default KillSwitch;
