import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOktaAuth } from './OktaContext';
import OktaError from './OktaError';

const LoginCallback = ({ errorComponent, loadingElement = null, onAuthResume, ...props }) => {
  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (onAuthResume && oktaAuth.isInteractionRequired && oktaAuth.isInteractionRequired()) {
      onAuthResume();
      return;
    }

    if (oktaAuth.isLoginRedirect()) {
      oktaAuth
        .handleLoginRedirect()
        .then(() => {
          // In `<Security>` component service was not started in case of login redirect.
          // Start it now after `restoreOriginalUri` has been called and route changed.
          oktaAuth.start();
        })
        .catch((e) => {
          if (e.message) {
            navigate(`/login`, { error: e });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth]);

  const authError = authState && authState.error;
  const displayError = authError;
  const ErrorReporter = errorComponent || OktaError;

  if (displayError) {
    return <ErrorReporter error={displayError} />;
  }

  return loadingElement;
};

export default LoginCallback;
