import { useNotify, useRefresh, useRecordContext } from 'react-admin';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import PublishIcon from '@mui/icons-material/Publish';
import { request } from '../../services/request';
import { useOktaAuth } from '../../security/OktaContext';

const PREFIX = 'QueueButton';

const classes = {
  approveButton: `${PREFIX}-approveButton`,
  approveIcon: `${PREFIX}-approveIcon`,
};

const StyledButton = styled(Button)(({ theme }) => ({
  [`&.${classes.approveButton}`]: {
    zIndex: 3000,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.approveIcon}`]: {
    fontSize: 20,
    marginRight: theme.spacing(0.5),
  },
}));

const apiUrl = `${import.meta.env.VITE_API_URL}/v1`;

const QueueButton = (props) => {
  const { oktaAuth } = useOktaAuth();
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = async () => {
    const requestUrl = `${apiUrl}/archives/${record.id}/move-to-queues`;
    const AuthorizationString = `Bearer ${oktaAuth.getAccessToken()}`;

    const requestOptions = {
      url: requestUrl,
      method: 'put',
      headers: {
        Accept: 'application/json',
        Authorization: AuthorizationString,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(record),
    };

    try {
      await request(requestOptions);
      refresh();
      notify('Post moved to queues', { type: 'success' });
    } catch (err) {
      notify(err.errorData.message, { type: 'warning' });
    }
  };

  return (
    <StyledButton
      color="primary"
      size="small"
      onClick={handleClick}
      className={classes.approveButton}
    >
      <PublishIcon className={classes.approveIcon} />
      Move to Queues
    </StyledButton>
  );
};

export default QueueButton;
