import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Edit, DateField, SaveButton, SimpleForm, TextInput, Toolbar, Labeled } from 'react-admin';

const schema = yup.object({
  title: yup.string().required('Location title is required'),
});

const LocationToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton label="Save" />
    </Toolbar>
  );
};

const LocationEdit = (props) => {
  return (
    <Edit title="Edit Location">
      <SimpleForm toolbar={<LocationToolbar />} resolver={yupResolver(schema)}>
        <TextInput source="id" disabled />
        <TextInput label="Location Title" source="location" />
        <TextInput source="slug" disabled />
        <TextInput label="Location Code" source="code" />

        <Labeled label="Created At">
          <DateField source="createdAt" showTime />
        </Labeled>
        <Labeled label="Updated At">
          <DateField source="updatedAt" showTime />
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};

export default LocationEdit;
